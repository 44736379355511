import { MapHostnameToPubstackKey } from 'constants/ads'

import { isInternalHostname, normalizeHost } from '_libs/utils/url'

export function getPubstackDomainKey(hostname: string) {
  const normalizedHostname = normalizeHost(hostname)

  if (!isInternalHostname(normalizedHostname)) return null

  return MapHostnameToPubstackKey[normalizedHostname]
}
