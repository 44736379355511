import { useRef } from 'react'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import clientSideMetrics from '_libs/common/client-side-metrics'

import { MS_PER_SECOND } from 'constants/date'
import { AdPage, AdPlatform, AdShape } from 'constants/ads'

type Props = {
  countryCode?: string
  shape: AdShape | 'Rokt'
  page: AdPage
  platform: AdPlatform
}

function useAdLoadtimeLogging({ countryCode, shape, page, platform }: Props) {
  const isAdsLoadTimeClientSideMetricsEnabled = useFeatureSwitch(
    'web_ads_load_time_client_side_metrics',
  )

  const adRequestTimestampRef = useRef(0)

  const onRequest = () => {
    if (!isAdsLoadTimeClientSideMetricsEnabled) return

    adRequestTimestampRef.current = Date.now()
  }

  const onLoad = () => {
    if (!adRequestTimestampRef.current || !isAdsLoadTimeClientSideMetricsEnabled || !countryCode)
      return

    const adLoadTime = Date.now() - adRequestTimestampRef.current

    clientSideMetrics
      .histogram('ad_load', {
        country_code: countryCode,
        shape,
        page,
        platform,
      })
      .observe(adLoadTime / MS_PER_SECOND)
  }

  return { onRequest, onLoad }
}

export default useAdLoadtimeLogging
