import { useEffect, useState } from 'react'

import { toParams } from '_libs/utils/url'
import { serverSide } from '_libs/utils/environment'
import useLocation from 'hooks/useLocation'

const eventTarget = new EventTarget()
const CHANGE_EVENT = 'change'

if (!serverSide) {
  const { pushState, replaceState } = window.history

  const onChange = () => eventTarget.dispatchEvent(new CustomEvent(CHANGE_EVENT))

  window.history.pushState = function pushStateOverride(...args) {
    pushState.apply(this, args)
    onChange()
  }

  window.history.replaceState = function replaceStateOverride(...args) {
    replaceState.apply(this, args)
    onChange()
  }
}

const useQueryParams = () => {
  const location = useLocation()
  const [queryParams, setQueryParams] = useState(() => location.searchParams)

  useEffect(() => {
    const callBack = () => {
      setQueryParams(toParams(window.location.search))
    }

    eventTarget.addEventListener(CHANGE_EVENT, callBack)

    return () => eventTarget.removeEventListener(CHANGE_EVENT, callBack)
  }, [])

  return queryParams
}

export default useQueryParams
