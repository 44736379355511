'use client'

import { Menu24, X24 } from '@vinted/monochrome-icons'
import { Button, ColorCode } from '@vinted/web-ui'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { clickEvent } from '_libs/common/event-tracker/events'

type Props = {
  isActive: boolean
  onToggle(): void
}

const HeaderToggleMobileMenu = ({ isActive, onToggle }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('header')

  function trackClick() {
    track(
      clickEvent({
        target: ClickableElement.NavigationMenuToggle,
      }),
    )
  }

  function handleToggleClick() {
    trackClick()
    onToggle()
  }

  const icon = isActive ? X24 : Menu24
  const menuKey = isActive ? 'close' : 'open'

  return (
    <Button
      onClick={handleToggleClick}
      iconName={icon}
      iconColor={ColorCode.GreyscaleLevel3}
      styling={Button.Styling.Flat}
      aria={{ 'aria-label': translate(`a11y.menu.${menuKey}`) }}
    />
  )
}

export default HeaderToggleMobileMenu
