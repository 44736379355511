'use client'

import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text, Icon, Button } from '@vinted/web-ui'
import { Bookmark24, BookmarkFilled24 } from '@vinted/monochrome-icons'

import { useSession } from '@marketplace-web/shared/session'
import { toggleSearchSubscriptionEvent } from '_libs/common/event-tracker/events'

import { Screen } from 'constants/tracking/screens'
import { CatalogDto } from 'types/dtos'
import useTracking from 'hooks/useTracking'
import useBreakpoint from 'hooks/useBreakpoint'
import useTranslate from 'hooks/useTranslate'
import { SearchSubscribeModal } from 'components/SavedSearches'
import { useSavedSearchesContext } from 'components/Header/SavedSearchesProvider'
import { urlToApiParams } from 'components/Header/SavedSearchesProvider/transformers'
import { logSavedCategoryEvent } from '_libs/common/braze/utils/event-loggers'
import { getSearchSessionData } from '_libs/utils/search'
import useQueryParams from 'hooks/useQueryParams'

import useSavedSearchesSubscribeEducation from '../SearchSubscribeModal/useSavedSearchesSubscribeEducation'

type Props = {
  catalogTree: Array<CatalogDto>
}

const SearchSubscribeButton = ({ catalogTree }: Props) => {
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const translate = useTranslate()
  const { user } = useSession()
  const userId = user?.id

  const queryParams = useQueryParams()

  const [isLoading, setIsLoading] = useState(false)

  const { currentSearch, actions } = useSavedSearchesContext()

  const {
    showSearchSubscriptionEducation,
    closeSearchSubscriptionEducation,
    isSubscribedModalOpen,
  } = useSavedSearchesSubscribeEducation()

  const toggleSubscription = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    const params = urlToApiParams(new URL(window.location.href))
    const searchSessionData = getSearchSessionData()

    if (isLoading) {
      event.preventDefault()

      return
    }

    if (!userId) return

    setIsLoading(true)
    actions.toggleCurrentSearchSubscription(params).finally(() => setIsLoading(false))

    if (!currentSearch?.subscribed) {
      showSearchSubscriptionEducation()
      logSavedCategoryEvent(
        params.catalog_id,
        user.external_id,
        catalogTree,
        [queryParams.brand_ids || []].flat(),
      )
    }

    track(
      toggleSearchSubscriptionEvent({
        savedRecentSearchSessionId: null,
        savedRecentSearchListId: null,
        screen: Screen.Catalog,
        isSubscribing: !currentSearch?.subscribed,
        searchSessionId: searchSessionData.searchSessionId || '',
        searchTitle: currentSearch?.title,
        searchQuery: currentSearch?.search_text ?? params.search_text,
        globalSearchSessionId: searchSessionData.globalSearchSessionId,
      }),
    )
  }

  if (queryParams.disable_search_saving === 'true' || !userId) return null

  const action = currentSearch?.subscribed ? 'delete' : 'save'
  const text = <FormattedMessage id={`saved_searches.actions.${action}`} />

  const a11yLabel = breakpoints.phones ? translate(`saved_searches.a11y.${action}`) : undefined

  return (
    <>
      <SearchSubscribeModal
        isOpen={isSubscribedModalOpen}
        onClose={closeSearchSubscriptionEducation}
      />

      <Button
        isLoading={isLoading}
        text={
          !breakpoints.phones && (
            <Text type={Text.Type.Subtitle} theme="amplified" as="span">
              {text}
            </Text>
          )
        }
        icon={
          <Icon
            name={currentSearch?.subscribed ? BookmarkFilled24 : Bookmark24}
            color={currentSearch?.subscribed ? Icon.Color.Primary : Icon.Color.GreyscaleLevel1}
          />
        }
        onClick={toggleSubscription}
        aria={{
          'aria-label': a11yLabel,
        }}
        testId={`catalog-search-${action}-button`}
        theme="muted"
      />
    </>
  )
}

export default SearchSubscribeButton
