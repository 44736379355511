import classNames from 'classnames'
import { ReactNode } from 'react'

import Advertisement from 'components/Advertisement'
import AdvertisementBlockCheck from 'components/AdvertisementBlockCheck'
import AppBanner from 'components/AppBanner'
import ErrorBoundary from 'components/ErrorBoundary'
import FlashMessage from 'components/FlashMessage'
import { GoogleAnalyticsTracker } from 'components/Google/Analytics'
import VacationNotification from 'components/VacationNotification'
import { AdShape } from 'constants/ads'

type Props = {
  isWebGa4AnalyticsEnabled: boolean
  isWebview: boolean
  shouldBreakoutLayout: boolean
  children: ReactNode
}

const Content = ({
  isWebview,
  isWebGa4AnalyticsEnabled,
  shouldBreakoutLayout,
  children,
}: Props) => (
  <main className="site">
    <div className="site-wrapper">
      <ErrorBoundary FallbackComponent={ErrorBoundary.LayoutError}>
        <Advertisement shape={AdShape.Leaderboard} id="ad-leaderboard" />

        <div className="site-content">
          <div className={classNames({ container: !shouldBreakoutLayout })}>
            <div className={classNames({ container: shouldBreakoutLayout })}>
              {!isWebview && <VacationNotification />}
            </div>

            <div
              className={classNames('row u-position-relative', {
                'u-margin-horizontal-none': shouldBreakoutLayout,
              })}
            >
              <div
                id="content"
                className={classNames('grid12', {
                  'u-padding-horizontal-none': shouldBreakoutLayout,
                })}
              >
                <div className="body-content">
                  <FlashMessage />
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>

        <AppBanner />
        <AdvertisementBlockCheck />
        {isWebGa4AnalyticsEnabled && <GoogleAnalyticsTracker />}
      </ErrorBoundary>
    </div>
  </main>
)

export default Content
