import { Spacer } from '@vinted/web-ui'

import MainFooter from 'components/MainFooter'
import PolicyFooter from 'components/PolicyFooter'

type Props = {
  isBusinessInfoLinksEnabled: boolean
  isDigitalLawEnabled: boolean
  isPolicyFooterStatic: boolean
  hasNoNavigation: boolean
  hidePolicyFooterDivider: boolean
  onlineComplaintUrl: string | undefined
  showSpaceBeforeMainFooter: boolean
}

const Footer = ({
  isBusinessInfoLinksEnabled,
  isDigitalLawEnabled,
  isPolicyFooterStatic,
  hasNoNavigation,
  hidePolicyFooterDivider,
  onlineComplaintUrl,
  showSpaceBeforeMainFooter,
}: Props) => {
  if (hasNoNavigation) {
    return (
      <PolicyFooter
        isDigitalLawEnabled={isDigitalLawEnabled}
        isBusinessInfoLinksEnabled={isBusinessInfoLinksEnabled}
        onlineComplaintUrl={onlineComplaintUrl}
        isStatic={isPolicyFooterStatic}
        noDivider={hidePolicyFooterDivider}
      />
    )
  }

  return (
    <>
      {showSpaceBeforeMainFooter && <Spacer size={Spacer.Size.X5Large} />}
      <div className="u-ui-margin-top-auto">
        <MainFooter
          isBusinessInfoLinksEnabled={isBusinessInfoLinksEnabled}
          isDigitalLawEnabled={isDigitalLawEnabled}
          onlineComplaintUrl={onlineComplaintUrl}
        />
      </div>
    </>
  )
}

export default Footer
