'use client'

import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import AdsProvider from 'containers/AdsProvider'

import DomainSelectModal from 'components/DomainSelectModal'
import Header from 'components/Header'
import HeaderNoNavigation from 'components/HeaderNoNavigation'
import InAppMessage from '_libs/common/braze/components/InAppMessage'
import GlobalTwoFaModal from 'components/GlobalTwoFAModal'
import {
  INBOX_PAGES,
  LAYOUT_BREAKOUT_PAGES,
  NO_SPACER_BEFORE_MAIN_FOOTER_PAGES,
  NON_WHITE_BACKGROUND_PAGES,
  POLICY_FOOTER_PAGES,
} from 'constants/layouts/full'
import { CatalogDto, CountryLinkDto, LanguageDto } from 'types/dtos'
import { SearchBarSearchType } from 'constants/search'
import useSession from 'hooks/useSession'
import usePageId from 'hooks/usePageId'
import useSystemConfiguration from 'hooks/useSystemConfiguration'
import { SavedSearchesProvider } from 'components/Header/SavedSearchesProvider'
import { useEnableItemPageRedesign } from 'pages/Item/hooks'

import { AdsPlacementModel } from 'types/models'

import AdScripts from '../../components/AdScripts'
import Content from '../../components/Layout/Full/Content/Content'
import Footer from '../../components/Layout/Full/Footer/Footer'
import { withLayout } from '../../libs/server-utils/hoc'

export type Props = {
  languages: Array<LanguageDto>
  catalogTree: Array<CatalogDto>
  hasNoNavigation: boolean
  domainLinks: Array<CountryLinkDto> | null
  children: ReactNode
  adsPlacements: Array<AdsPlacementModel>
  adsSegments: Record<string, string>
  shouldMockAds: boolean
}

const resolveHeaderSearchType = (pathname: string): SearchBarSearchType => {
  const FIRST_VALUE_AFTER_SLASH_INDEX = 1
  const firstValueAfterSlash = pathname.split('/')[FIRST_VALUE_AFTER_SLASH_INDEX]

  if (firstValueAfterSlash === 'member') return SearchBarSearchType.User
  if (firstValueAfterSlash === 'help') return SearchBarSearchType.Faq

  return SearchBarSearchType.Item
}

function useWhiteBackground() {
  const pageId = usePageId()
  const isUpdatedFeedbackPageEnabled = useFeatureSwitch('updated_feedback_page')
  const isItemPageRedesignEnabled = useEnableItemPageRedesign()

  if (!pageId) return true
  if (pageId === 'feedback') return isUpdatedFeedbackPageEnabled
  if (pageId === 'item') return isItemPageRedesignEnabled

  return !NON_WHITE_BACKGROUND_PAGES.includes(pageId)
}

export const FullPageLayout = ({
  languages,
  catalogTree,
  hasNoNavigation,
  domainLinks,
  children,
  adsPlacements,
  adsSegments,
  shouldMockAds,
}: Props) => {
  const systemConfiguration = useSystemConfiguration()
  const isWebGa4AnalyticsEnabled = useFeatureSwitch('web_ga4_analytics')
  const { isContentOnlyView: isContentOnly } = useSession()
  const router = useRouter()
  const { isWebview } = useSession()
  const isWhiteBackground = useWhiteBackground()
  const pageId = usePageId() || 'unknown'

  if (!systemConfiguration) return null

  const renderHeader = () => {
    if (isContentOnly) return null

    if (hasNoNavigation) {
      return <HeaderNoNavigation />
    }

    return (
      <Header
        languages={languages}
        catalogTree={catalogTree}
        searchType={resolveHeaderSearchType(router.pathname)}
        impressumUrl={systemConfiguration.urls.impressum}
        isBusinessAccountLinksVisible={systemConfiguration.businessAccountInformationalLinksVisible}
        isOurPlatformVisible={!!systemConfiguration.urls.ourPlatform}
      />
    )
  }

  return (
    <AdsProvider placements={adsPlacements} segments={adsSegments} shouldMockAds={shouldMockAds}>
      {/*
        It is important to keep `AdScripts` above
        `Advertisement` component to initialize various
        third party scripts like googletag or etc.
        that are necessary for `Advertisement` component
        to actually render an advertisement.
      */}
      <AdScripts />

      <div
        className={classNames('fullpage-layout is-wide', {
          'u-background-white': isWhiteBackground,
        })}
      >
        <InAppMessage />
        {domainLinks && <DomainSelectModal domainLinks={domainLinks} />}

        <SavedSearchesProvider>
          {renderHeader()}
          <Content
            isWebGa4AnalyticsEnabled={isWebGa4AnalyticsEnabled}
            isWebview={isWebview}
            shouldBreakoutLayout={LAYOUT_BREAKOUT_PAGES.includes(pageId)}
          >
            {children}
          </Content>
        </SavedSearchesProvider>

        {!isContentOnly && (
          <Footer
            isBusinessInfoLinksEnabled={
              systemConfiguration.businessAccountInformationalLinksVisible
            }
            isDigitalLawEnabled={!!systemConfiguration.urls.ourPlatform}
            isPolicyFooterStatic={INBOX_PAGES.includes(pageId)}
            hasNoNavigation={hasNoNavigation || POLICY_FOOTER_PAGES.includes(pageId)}
            hidePolicyFooterDivider={INBOX_PAGES.includes(pageId)}
            onlineComplaintUrl={systemConfiguration.urls.onlineComplaints}
            showSpaceBeforeMainFooter={!NO_SPACER_BEFORE_MAIN_FOOTER_PAGES.includes(pageId)}
          />
        )}
        <GlobalTwoFaModal />
      </div>
    </AdsProvider>
  )
}

export default withLayout<Props>(FullPageLayout)
