'use client'

import { createRef, Component, RefObject, ReactNode, DragEvent as ReactDragEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text } from '@vinted/web-ui'
import { throttle, includes } from 'lodash'

const EVENT_THROTTLE_MS = 100

type Props = {
  onDrop: (files: Array<File>) => void
  children?: ReactNode
}

type State = {
  isDragging: boolean
}

class Dropzone extends Component<Props, State> {
  node: RefObject<HTMLDivElement> = createRef()

  state: Readonly<State> = {
    isDragging: false,
  }

  throttledMouseOut = throttle((event: MouseEvent) => {
    this.onMouseOut(event)
  }, EVENT_THROTTLE_MS)

  componentDidMount() {
    window.document.addEventListener('dragenter', this.onDragStart)
    window.document.addEventListener('mouseout', this.throttledMouseOut)
  }

  componentWillUnmount() {
    window.document.removeEventListener('dragenter', this.onDragStart)
    window.document.removeEventListener('mouseout', this.throttledMouseOut)
  }

  // TODO: convert into functional component
  /**
   * Validates if draggable item contains files
   */
  // eslint-disable-next-line class-methods-use-this
  validateDraggedItem = (event: ReactDragEvent | DragEvent) =>
    event.dataTransfer?.types &&
    event.dataTransfer.types.length > 0 &&
    includes(event.dataTransfer.types, 'Files')

  // Drop handling only when used on dropzone
  onDrop = (event: ReactDragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const target = event.currentTarget
    const domNode = this.node.current

    if (!domNode || (target && !domNode.contains(target)) || !this.validateDraggedItem(event)) {
      return
    }

    if (!event.dataTransfer) return

    const { onDrop } = this.props

    this.setState({ isDragging: false })

    if (onDrop) onDrop(Array.from(event.dataTransfer.files))
  }

  // Checks browser event if files being dragged
  onDragStart = (event: DragEvent) => {
    if (this.state.isDragging) return

    if (this.validateDraggedItem(event)) {
      this.setState({ isDragging: true })
    }
  }

  // Detects browser window leave, and only when dragging
  onMouseOut = (event: MouseEvent) => {
    if (!this.state.isDragging || event.relatedTarget) return

    this.setState({ isDragging: false })
  }

  // TODO: convert into functional component
  /**
   * Prevent default behaviour (Prevents file from being opened)
   */
  // eslint-disable-next-line class-methods-use-this
  onDragOver = (event: ReactDragEvent) => {
    event.preventDefault()
  }

  render() {
    return (
      <div className="dropzone" data-testid="dropzone">
        <div
          data-testid="dropzone-overlay"
          className="dropzone__overlay"
          style={{ display: this.state.isDragging ? 'flex' : 'none' }}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
          ref={this.node}
        >
          <div className="dropzone__overlay-description">
            <Text
              as="h1"
              text={<FormattedMessage id="dropzone.description" />}
              type={Text.Type.Heading}
              theme="muted"
            />
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default Dropzone
