'use client'

import { MouseEvent } from 'react'
import { Image } from '@vinted/web-ui'
import classNames from 'classnames'
import { noop } from 'lodash'

import { useSession } from '@marketplace-web/shared/session'
import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ROOT_URL } from 'constants/routes'
import { clickEvent } from '_libs/common/event-tracker/events'

type Props = {
  onClick?: (event: MouseEvent) => void
}

const HeaderLogo = ({ onClick = noop }: Props) => {
  const translate = useTranslate('header.a11y')
  const { track } = useTracking()
  const { user } = useSession()

  const asset = useAsset('assets/web-logo/default')

  const handleLogoClick = (event: MouseEvent) => {
    const trakingEvent = clickEvent({
      target: ClickableElement.Logo,
    })

    track(trakingEvent)

    return onClick(event)
  }

  const renderDesktopLogo = () => (
    <div className="l-header__logo-container">
      <Image src={asset('logo.svg')} alt={translate('logo')} />
    </div>
  )

  const renderLogo = () => <div className="u-ui-padding-left-small">{renderDesktopLogo()}</div>

  const renderAuthenticatedLogo = () => (
    <>
      <div className="u-phones-only">
        <Image src={asset('symbol.svg')} alt={translate('logo')} />
      </div>

      <div className={classNames('u-tablets-up-only', 'u-ui-padding-left-small')}>
        {renderDesktopLogo()}
      </div>
    </>
  )

  return (
    <div className="l-header__logo">
      <a
        className="u-block"
        href={ROOT_URL}
        title={translate('logo')}
        aria-label={translate('logo')}
        onClick={handleLogoClick}
        data-testid="header-logo-id"
      >
        {user ? renderAuthenticatedLogo() : renderLogo()}
      </a>
    </div>
  )
}

export default HeaderLogo
