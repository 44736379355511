'use client'

import { useState } from 'react'
import { Button, Cell, Divider, Image, Rating, Spacer, Text } from '@vinted/web-ui'

import { useSession } from '@marketplace-web/shared/session'
import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useUserAgent from 'hooks/useUserAgent'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from '_libs/common/event-tracker/events'
import * as device from '_libs/utils/userAgentParser'
import { PageId } from 'types/page-id'
import useIsInPage from 'hooks/useIsInPage'
import { cookiesDataByName } from '_libs/common/cookie-manager/cookies-data'
import useCookie from '_libs/common/cookie-manager/hooks/useCookie'

import useClientSideOnly from '../../../next/hooks/useClientSideOnly'

// It is decided to have hardcoded app rating with no need to sync it with store
const APP_RATING = 4.5
const APP_URL =
  'https://4uwb.adj.st/?adjust_t=l2fpdc8_rnxf4nj&adjust_campaign=UX_SmartBanner&adjust_engagement_type=fallback_click'
const LATEST_ALLOWED_ANDROID_VERSION = 7

const appBannerPageIds: Array<PageId> = ['root', 'member', 'catalog', 'catalog-by-id', 'brand']

type Props = {
  isHeader?: boolean
}

const AppBanner = ({ isHeader }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('app_banner')
  const userAgent = useUserAgent()
  const userId = useSession().user?.id
  const { screen } = useSession()
  const asset = useAsset('/assets/logo/square')
  const isClientSide = useClientSideOnly()
  const cookies = useCookie()

  const getAndroidVersion = () => {
    const match = userAgent.toLowerCase().match(/android\s([0-9.]*)/i)

    return match ? parseInt(match[1]!, 10) : LATEST_ALLOWED_ANDROID_VERSION
  }

  const shouldNotRenderOnMobile = () =>
    !device.isIOS(userAgent) &&
    !(device.isAndroid(userAgent) && getAndroidVersion() >= LATEST_ALLOWED_ANDROID_VERSION)

  const [isHidden, setIsHidden] = useState(false)

  const isInPage = useIsInPage(appBannerPageIds)

  if (!isClientSide) return null
  if (isHidden) return null
  if (userId) return null
  if (cookies.get(cookiesDataByName.app_banner)) return null
  if (shouldNotRenderOnMobile()) return null
  if (!isInPage) return null

  if (!isHeader && screen === Screen.NewsFeed) return null

  function trackClick(target: ClickableElement) {
    track(
      clickEvent({
        target,
        targetDetails: device.parseOS(userAgent),
      }),
    )
  }

  function handleOpenClick() {
    trackClick(ClickableElement.InstallAppBanner)
  }

  function handleCloseClick() {
    setIsHidden(true)
    trackClick(ClickableElement.CancelInstallAppBanner)
    cookies.set(cookiesDataByName.app_banner, 'hidden')
  }

  function renderActions() {
    return (
      <div className="u-flexbox">
        <Button
          size={Button.Size.Medium}
          text={translate('close_cta')}
          onClick={handleCloseClick}
          testId="app-banner-close-button"
        />
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <Button
          theme="primary"
          styling={Button.Styling.Filled}
          size={Button.Size.Medium}
          text={translate('cta')}
          onClick={handleOpenClick}
          url={APP_URL}
          testId="app-banner-open-button"
        />
      </div>
    )
  }

  function renderTabletActions() {
    return <div className="u-tablets-only">{renderActions()}</div>
  }

  function renderPhoneActions() {
    return (
      <div className="u-phones-only">
        <Spacer size={Spacer.Size.Large} />
        <Cell styling={Cell.Styling.Tight}>{renderActions()}</Cell>
      </div>
    )
  }

  function renderTitle() {
    return (
      <div className="u-flexbox">
        <Text as="h2" type={Text.Type.Title} text={translate('title')} truncate />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <div>
          <Spacer size={Spacer.Size.XSmall} />
          <Rating value={APP_RATING} />
        </div>
      </div>
    )
  }

  return (
    <div className="app-banner">
      <div
        className={isHeader ? 'sticky-header' : 'sticky-footer'}
        data-testid="app-banner-wrapper"
      >
        <div className="u-line-height-default">
          {!isHeader && <Divider />}
          <Cell>
            <Cell
              styling={Cell.Styling.Tight}
              prefix={<Image size={Image.Size.Large} src={asset('transparent.png')} />}
              suffix={renderTabletActions()}
              title={renderTitle()}
              body={<Text as="span" text={translate('description')} truncate />}
              fullWidthTitle
            />

            {renderPhoneActions()}
          </Cell>
          {isHeader && <Divider />}
        </div>
      </div>
    </div>
  )
}

export default AppBanner
