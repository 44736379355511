import { useCallback, useMemo, useRef, useState } from 'react'

import { getUserNotifications } from 'data/api'
import { transformUserNotificationDto } from 'data/transformers/generic-inbox-notification'
import { transformBrazeInboxCardNotificationDto } from '_libs/common/braze/transformers'
import { UiState } from 'constants/ui'
import { sortNotifications, sortPaginatedNotifications } from '_libs/utils/notification'
import { GenericInboxNotificationModel } from 'types/models'

import useBrazeInboxNotificationCards from '_libs/common/braze/hooks/useBrazeInboxNotificationCards'

type Args = {
  perPage?: number
  isPaginated?: boolean
}

const NOTIFICATIONS_PER_PAGE_DROPDOWN = 5

// TODO: currently, we resolve success state after backend notifications are fetched
// meaning Braze notifications could come later on and cause flickering
const useFetchInboxNotifications = ({
  perPage = NOTIFICATIONS_PER_PAGE_DROPDOWN,
  isPaginated,
}: Args = {}) => {
  const [uiState, setUiState] = useState(UiState.Idle)
  const [isFirstPageFetched, setIsFirstPageFetched] = useState(false)
  const [userNotifications, setUserNotifications] = useState<Array<GenericInboxNotificationModel>>(
    [],
  )
  const [isEndReached, setIsEndReached] = useState(false)

  const currentPage = useRef(0)

  const { brazeNotificationCards, brazeControlNotificationCards } = useBrazeInboxNotificationCards()

  const brazeNotifications = useMemo(
    () =>
      brazeNotificationCards
        ? brazeNotificationCards.map(card => transformBrazeInboxCardNotificationDto(card))
        : [],
    [brazeNotificationCards],
  )

  const brazeNotificationCount = brazeNotifications.filter(
    notification => !notification.isViewed,
  ).length

  const unsortedNotifications = [...userNotifications, ...brazeNotifications]
  const sortedNotifications = isPaginated
    ? sortPaginatedNotifications(unsortedNotifications, isEndReached)
    : sortNotifications(unsortedNotifications)

  const fetchUserNotifications = useCallback(async () => {
    setUiState(UiState.Pending)
    currentPage.current += 1

    const response = await getUserNotifications({ perPage, page: currentPage.current })
    if ('errors' in response) {
      setUiState(UiState.Failure)
      setIsEndReached(true)

      return
    }

    if (!response.notifications.length) {
      setUiState(UiState.Success)
      setIsEndReached(true)
      setIsFirstPageFetched(true)

      return
    }
    const newNotifications = response.notifications.map(transformUserNotificationDto)

    setUserNotifications(prevNotifications => [...prevNotifications, ...newNotifications])
    setIsFirstPageFetched(true)
    setUiState(UiState.Success)
    setIsEndReached(response.pagination.total_pages <= currentPage.current)
  }, [perPage])

  return {
    notifications: isFirstPageFetched ? sortedNotifications : [],
    setNotifications: setUserNotifications,
    fetchUserNotifications,
    controlNotificationCards: brazeControlNotificationCards,
    brazeNotificationCount,
    uiState,
    isEndReached,
  }
}

export default useFetchInboxNotifications
