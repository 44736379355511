import { AbTestVariant } from 'constants/abtest'
import useAbTest from 'hooks/useAbTest'

export const useEnableItemPageRedesign = () => {
  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q4' })?.variant === AbTestVariant.On
  const isItemPageRedesignEnabled =
    useAbTest({
      abTestName: 'item_page_redesign_web',
    })?.variant === AbTestVariant.On && buyerHoldoutAbTestEnabled

  return isItemPageRedesignEnabled
}
