'use client'

import { Divider } from '@vinted/web-ui'
import classNames from 'classnames'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { CookieConsentVersion } from 'constants/consent'
import {
  BUSINESS_TERMS_AND_CONDITIONS_URL,
  BUSINESS_TERMS_OF_SALE_URL,
  BUSINESS_TERMS_URL,
  COOKIE_POLICY_URL,
  OUR_PLATFORM_URL,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from 'constants/routes'

import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'
import useConsent from 'hooks/useConsent'

import { getOldHostRedirectUrl } from '_libs/utils/url'
import { toggleOneTrustInfoDisplay } from '_libs/utils/window'

type Props = {
  isDigitalLawEnabled: boolean
  isBusinessInfoLinksEnabled?: boolean
  onlineComplaintUrl?: string
  isStatic?: boolean
  noDivider?: boolean
}

const PolicyFooter = ({
  isDigitalLawEnabled,
  isBusinessInfoLinksEnabled,
  onlineComplaintUrl,
  isStatic,
  noDivider,
}: Props) => {
  const translate = useTranslate('footer.policy.items')
  const isProTermsAndConditionsFSEnabled = useFeatureSwitch('pro_terms_and_conditions_enabled')
  const { host } = useLocation()

  const { cookieConsentVersion, isCookieConsentVersion } = useConsent()

  const className = classNames('privacy-footer', isStatic && 'privacy-footer--static')
  const cookieSettingsText =
    cookieConsentVersion === CookieConsentVersion.California
      ? translate('cookie_settings_us')
      : translate('cookie_settings')

  function handleCookieSettingsClick() {
    toggleOneTrustInfoDisplay()
  }

  const renderBusinessAccountLinks = () => {
    if (!isBusinessInfoLinksEnabled) return null

    if (isProTermsAndConditionsFSEnabled) {
      return (
        <a
          href={BUSINESS_TERMS_AND_CONDITIONS_URL}
          data-testid="privacy-footer-pro-terms-and-conditions-link"
        >
          {translate('pro_terms_and_conditions')}
        </a>
      )
    }

    return (
      <>
        <a href={BUSINESS_TERMS_OF_SALE_URL} data-testid="privacy-footer-pro-sale-term-link">
          {translate('pro_terms_of_sale')}
        </a>
        <a href={BUSINESS_TERMS_URL} data-testid="privacy-footer-pro-use-term-link">
          {translate('pro_terms_of_use')}
        </a>
      </>
    )
  }

  return (
    <footer className={className}>
      <div className="privacy-footer__content">
        <div className="container">
          {!noDivider && <Divider />}
          <div className="privacy-footer__content__links">
            <a href={getOldHostRedirectUrl(host) + PRIVACY_POLICY_URL}>
              {translate('privacy_policy')}
            </a>
            <a href={COOKIE_POLICY_URL}>{translate('cookie_policy')}</a>
            {isCookieConsentVersion && (
              <button type="button" onClick={handleCookieSettingsClick}>
                {cookieSettingsText}
              </button>
            )}
            <a href={TERMS_URL}>{translate('terms_and_conditions')}</a>
            {isDigitalLawEnabled && <a href={OUR_PLATFORM_URL}>{translate('our_platform')}</a>}
            {renderBusinessAccountLinks()}
            {onlineComplaintUrl && (
              <a href={onlineComplaintUrl}>{translate('online_complaints')}</a>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default PolicyFooter
